@font-face {
  font-family: 'Geomanist';
  src: local('Geomanist'), url(./assets/fonts/geomanist-regular-webfont.ttf) format('truetype');
}
@font-face {
  font-family: 'Gobold Bold';
  src: local('Gobold Bold'), url(./assets/fonts/Gobold-Bold.otf) format('truetype');
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: Geomanist !important;
}
.home{
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
  width: 100vw;
}

.home .content{
  flex-grow: 1;
  padding: 30px 20px;
  background: #15122A;
}
a{
  text-decoration: none !important;
}
