.reward-card{
    background: #25223D;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 83px;
    margin-top: 20px;
}
.header-titlen{
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 11px;
        letter-spacing: 0.25px;
        color: #FFFFFF;
        opacity: 0.8;
        margin-bottom: 30px;
}
