.round-no{
    margin-bottom: 0;
    font-family: Geomanist;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8;
    margin-top: 25%;
    text-align: center;
}
.round-no-waitlist{
    margin-bottom: 0;
    font-family: Geomanist;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8;
    margin-top: 5%;
    text-align: center;
}
.round-header{
    margin-bottom: 0;
    font-family: Geomanist;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8;
}
tr{
    .player{
        text-align: center;
    }
}

.empty-records{
    text-align: center;
    width: 100%;
    padding: 20px;
    p{
        margin-bottom: 0;
        font-family: Geomanist;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 11px;
        letter-spacing: 0.25px;
        opacity: 0.8;
        margin-top: 5%;
        text-align: center;
        color: red;
    }
}
#th-empty{
    text-align: center   
}