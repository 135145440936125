.home{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100vh;
    width: 100vw;

    .content{
        flex-grow: 1;
        padding: 30px 20px;
        background: #15122A;
    }
}

.dHome {
  height: 75vh;
  max-width: 750px;
  width: 100vw;
  text-align: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .dContent{
      display: inline-block;
      padding: 30px 20px;
      background: #15122A;
  }

}

.header-title{
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 11px;
        letter-spacing: 0.25px;
        color: #FFFFFF;
        opacity: 0.8;
        margin-bottom: 30px;
}
// th{
//    !important;
// }
