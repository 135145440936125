.score-cards-with-title{
    background: #201b3f;
    border-radius: 5px;
    padding: 5px 0;
    margin: 0 5px 50px 5px;
    flex-grow: 1;

    .title{
        font-family: 'Gobold Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 11px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 8px;
        color: #DFE2E9;
        opacity: 0.5;
        justify-content: center;
        margin: 5px 0;
    }

    .number-card{
        background: #26204c;
        border-radius: 5px;
        height: 38.41px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        margin: 0 5px;

        .inner{
            height: 32.79px;
            background: #332d5f;
            border-radius: 5px;
            margin: 0 1px;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;

            &.logo{
                border: 1px solid rgba(255, 255, 255, 0.1);
                background: transparent;
            }
            h1{
                font-family: 'Gobold Bold';
                margin: 0;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 11px;
                letter-spacing: 0.25px;
                color: #FFFFFF;
            }
        }
        
    }
}


.score-cards{
    background: #26204c;
    border-radius: 5px;
    padding: 8px 0;
    margin: -5px 5px 50px 5px;
    flex-grow: 1;
    .number-card{
        background: #26204c;
        border-radius: 5px;
        height: 38.41px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        margin: 0 5px;
    .inner{
        height: 47px;
        background: #332d5f;
        border-radius: 5px;
        margin: 0 2px;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;

        &.logo{
            border: 1px solid rgba(255, 255, 255, 0.1);
            background: transparent;
        }
        h1{
            font-family: 'Gobold Bold';
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 11px;
            margin: 0;
            letter-spacing: 0.25px;
            color: #FFFFFF;
        }
    }
    }
}

.grand-box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    div{
        margin: -45px 20px;
        height: 50px;
        width: 70%;
        background-color: #26204c;
        justify-self: flex-end;
    }
}