.top{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.button-wrapper{
    width: 100%;
    text-align: center;
    margin: 20px 0 80px 0;
}

.rewards-unlocked{
    text-align: center;
}
