.loader2 {
  max-width: 750px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .pac{
    margin-top: 10px;
    margin-left: 50%;
  }

  .loaderContent2{
      padding-top: 10px;
      padding-bottom: 100px;
      flex-grow: 1;
      color: #bfa706;
  }
  p {
    margin-top: 10px;
    margin-left: 50%;
  }
  h1 {
    padding-left: 3px;
    padding-right: 3px;
  }
  h3 {
    padding-left: 5px;
    padding-right: 5px;
  }


}
