.play-cards{
    background: #F91F5C;
    border-radius: 10px;
    height: 126px;
    padding: 5px;
    flex-grow: 1;
    margin: 0 8px;
    cursor: pointer;

    .icon-box{
        height: 89px;
        background: #c71a4b;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3px;
    }
    .text-box{
        background: #951437;
        border-radius: 8px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            margin-bottom: 0;
            font-family: Geomanist;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 11px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #FFFFFF;
            opacity: 0.8;
        }
    }
}