.bottom_nav{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 77px;
    background: #28263c;
    padding: 16px 18px;
    position: sticky;
    bottom: 0;
}

.menu-active{

    .svg-icon{
        path{
            fill-opacity: unset !important;
        }
        
    }
}