.icon{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
    .title{
        font-family: Geomanist;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 11px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.25px;
        color: #CAC1EA;
        margin-top: 5px;
    }
}