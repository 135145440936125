.round-card-no-image{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    background: #ad1a43;
    border-radius: 10px;
    height: 22px;
    p{
        margin-bottom: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 11px;
        letter-spacing: 0.25px;
        color: #FFFFFF;
        opacity: 0.8;
    }
    img {
        width: 20px;
        margin: 2px;
    }
}

.round-card-no-image-alive{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  background: #156fcf;
  border-radius: 10px;
  height: 22px;
  p{
      margin-bottom: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 11px;
      letter-spacing: 0.25px;
      color: #FFFFFF;
      opacity: 0.8;
  }
  img {
      width: 15px;
      margin-top: 10px;
      margin: 8px;
  }
}


.round-card-you{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  background: #2e19a6;
  border-radius: 10px;
  height: 22px;
  p{
      margin-bottom: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 11px;
      letter-spacing: 0.25px;
      color: #FFFFFF;
      opacity: 0.8;
  }
}



.round-card-with-image{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img{
        margin-bottom: 10px;
    }

}
