.balance-card{
    background: #25223D;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 83px;
    flex-grow: 1;
    margin: 0 8px;
    padding: 10px;

    div{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        img{
            margin: 0 10px 0 0;
        }
        p{
            margin-bottom: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 11px;
            letter-spacing: 0.25px;
            color: #FFFFFF;
            opacity: 0.8;
        }
    }

    .price{
        font-family: 'Gobold Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 11px;
        text-align: left;
        letter-spacing: 0.25px;
        color: #FFFFFF;
    }
}