.reward-cardn{
    background: #25223D;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;    
    flex-grow: 1;
    margin: 0 8px;
    margin-top: 10px;
    padding: 10px;


    div{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        //align-items: center;

        p{
            margin-bottom: 0;
            margin-top: 20px;
            margin-right: 20px;
            margin-left: 20px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 11px;
            letter-spacing: 0.25px;
            float: left;
            color: #FFFFFF;
            opacity: 0.8;
        }

    }

}
