.rules-detail{

    .detail-nav{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        .item1{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            img{
                margin: 0 10px 0 0;
                height: 21.7px;
            }
        }
    }

    .text-body{
        margin-top: 20px;
        text-align: left;
        h4{
          color: #c23c08;
          font-weight: bold;
          text-decoration: underline;
        }
        p{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.25px;
            color: #FFFFFF;
            opacity: 0.8;
        }
        ol {
        	list-style-type: none;
        	counter-reset: li;

        }
        li {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.25px;
          color: #FFFFFF;
          opacity: 0.8;
        }

        li:before {
          counter-increment: li;
          content: counter(li, decimal-leading-zero);
        	color: red;
          font-weight: bold;
          font-size: 18px;
        	margin-right: 0.25em;
          margin-left: -20px
        }
    }

}
