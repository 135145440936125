.custom-button{
    background: #F91F5C;
    border-color: #F91F5C;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8;
    width: 119px;
    padding: 16px;
}