.loader {
  max-width: 750px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .loaderContent{
      padding-top: 30px;
      padding-bottom: 300px;
      flex-grow: 1;
      background: #383747;
      color: #bfa706;
  }
  p {
    margin: 0px;
  }
  h1 {
    padding-left: 3px;
    padding-right: 3px;
  }
  h3 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .recom{
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    border:3px solid #FF5733;
    border-radius: 25px;
    margin-top: 30px;
    margin-bottom: 50px;
    background: #504f63;
    h4 {
      margin-top: 5px;
      margin-bottom: 5px;
      color: #FF5733;
    }

  }

}
