.pagination{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .current-page{
        color: #F91F5C;
    }
    .total-records{
        color: #4d4b5d;
        font-family: Abel;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 11px;
        letter-spacing: 0.25px;
        opacity: 0.8;
        margin-bottom: 0;
    }
    .arrow-back,
    .arrow-front{
        cursor: pointer;
    }
}