.header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 86px;
    background: #28263c;
    padding: 16px;

    div{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .ligt-head{
            font-family: 'Gobold Bold';
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 11px;
            letter-spacing: 8px;
            color: #FFFFFF;
            opacity: 0.5;
        }
        .white-head{
            font-family: 'Gobold Bold';
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 11px;
            letter-spacing: 0.25px;
            color: #FFFFFF;
            margin-top: 7px;
        }
    }
}

.start{
    align-self: flex-start;
}
.end{
    align-self: flex-end;
    margin-right: 7px;
}
