.rule-card{
    background: #25223D;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: 18px 0;

    .title{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 0;
    }
}