.play{


    .play-btns{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .header-box{
        width: 100%;
        text-align: center;
    }
}
