@font-face {
  font-family: 'Geomanist';
  src: local('Geomanist'), url(/static/media/geomanist-regular-webfont.7da20100.ttf) format('truetype');
}
@font-face {
  font-family: 'Gobold Bold';
  src: local('Gobold Bold'), url(/static/media/Gobold-Bold.a213b3c5.otf) format('truetype');
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: Geomanist !important;
}
.home{
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
  width: 100vw;
}

.home .content{
  flex-grow: 1;
  padding: 30px 20px;
  background: #15122A;
}
a{
  text-decoration: none !important;
}

.loader {
  max-width: 750px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch; }
  .loader .loaderContent {
    padding-top: 30px;
    padding-bottom: 300px;
    flex-grow: 1;
    background: #383747;
    color: #bfa706; }
  .loader p {
    margin: 0px; }
  .loader h1 {
    padding-left: 3px;
    padding-right: 3px; }
  .loader h3 {
    padding-left: 5px;
    padding-right: 5px; }
  .loader .recom {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #FF5733;
    border-radius: 25px;
    margin-top: 30px;
    margin-bottom: 50px;
    background: #504f63; }
    .loader .recom h4 {
      margin-top: 5px;
      margin-bottom: 5px;
      color: #FF5733; }

.home {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
  width: 100vw; }
  .home .content {
    flex-grow: 1;
    padding: 30px 20px;
    background: #15122A; }

.dHome {
  height: 75vh;
  max-width: 750px;
  width: 100vw;
  text-align: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch; }
  .dHome .dContent {
    display: inline-block;
    padding: 30px 20px;
    background: #15122A; }

.header-title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  opacity: 0.8;
  margin-bottom: 30px; }

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 86px;
  background: #28263c;
  padding: 16px; }
  .header div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; }
    .header div .ligt-head {
      font-family: 'Gobold Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 11px;
      letter-spacing: 8px;
      color: #FFFFFF;
      opacity: 0.5; }
    .header div .white-head {
      font-family: 'Gobold Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 11px;
      letter-spacing: 0.25px;
      color: #FFFFFF;
      margin-top: 7px; }

.start {
  align-self: flex-start; }

.end {
  align-self: flex-end;
  margin-right: 7px; }

.bottom_nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  background: #28263c;
  padding: 16px 18px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0; }

.menu-active .svg-icon path {
  fill-opacity: unset !important; }

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .icon .title {
    font-family: Geomanist;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    color: #CAC1EA;
    margin-top: 5px; }


.rule-card {
  background: #25223D;
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 18px 0; }
  .rule-card .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 0; }

.reward-cardn {
  background: #25223D;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  margin: 0 8px;
  margin-top: 10px;
  padding: 10px; }
  .reward-cardn div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
    .reward-cardn div p {
      margin-bottom: 0;
      margin-top: 20px;
      margin-right: 20px;
      margin-left: 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 11px;
      letter-spacing: 0.25px;
      float: left;
      color: #FFFFFF;
      opacity: 0.8; }

.reward-card {
  background: #25223D;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 83px;
  margin-top: 20px; }

.header-titlen {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  opacity: 0.8;
  margin-bottom: 30px; }

.custom-button {
  background: #F91F5C;
  border-color: #F91F5C;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  opacity: 0.8;
  width: 119px;
  padding: 16px; }

.rules-detail .detail-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center; }
  .rules-detail .detail-nav .item1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center; }
    .rules-detail .detail-nav .item1 img {
      margin: 0 10px 0 0;
      height: 21.7px; }

.rules-detail .text-body {
  margin-top: 20px;
  text-align: left; }
  .rules-detail .text-body h4 {
    color: #c23c08;
    font-weight: bold;
    text-decoration: underline; }
  .rules-detail .text-body p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8; }
  .rules-detail .text-body ol {
    list-style-type: none;
    counter-reset: li; }
  .rules-detail .text-body li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8; }
  .rules-detail .text-body li:before {
    counter-increment: li;
    content: counter(li, decimal-leading-zero);
    color: red;
    font-weight: bold;
    font-size: 18px;
    margin-right: 0.25em;
    margin-left: -20px; }

.top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center; }

.button-wrapper {
  width: 100%;
  text-align: center;
  margin: 20px 0 80px 0; }

.input-wrapper {
  width: 100%;
  text-align: center;
  margin: 10px 0 10px 0; }

.results .rounds {
  width: 100%;
  text-align: center;
  margin-bottom: 50px; }
  .results .rounds p {
    font-family: 'Abel';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8; }

.round-nom {
  margin-bottom: 0;
  font-family: Geomanist;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  opacity: 0.8;
  margin-top: 13%;
  text-align: center; }

.round-no-waitlist {
  margin-bottom: 0;
  font-family: Geomanist;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  opacity: 0.8;
  margin-top: 5%;
  text-align: center; }

.round-header {
  margin-bottom: 0;
  font-family: Geomanist;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  opacity: 0.8; }

tr .player {
  text-align: center; }

.empty-records {
  text-align: center;
  width: 100%;
  padding: 20px; }
  .empty-records p {
    margin-bottom: 0;
    font-family: Geomanist;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 11px;
    letter-spacing: 0.25px;
    opacity: 0.8;
    margin-top: 5%;
    text-align: center;
    color: red; }

#th-empty {
  text-align: center; }

.round-card-no-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  background: #ad1a43;
  border-radius: 10px;
  height: 22px; }
  .round-card-no-image p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8; }
  .round-card-no-image img {
    width: 20px;
    margin: 2px; }

.round-card-no-image-alive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  background: #156fcf;
  border-radius: 10px;
  height: 22px; }
  .round-card-no-image-alive p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8; }
  .round-card-no-image-alive img {
    width: 15px;
    margin-top: 10px;
    margin: 8px; }

.round-card-you {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  background: #2e19a6;
  border-radius: 10px;
  height: 22px; }
  .round-card-you p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8; }

.round-card-with-image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .round-card-with-image img {
    margin-bottom: 10px; }

.loader2 {
  max-width: 750px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch; }
  .loader2 .pac {
    margin-top: 10px;
    margin-left: 50%; }
  .loader2 .loaderContent2 {
    padding-top: 10px;
    padding-bottom: 100px;
    flex-grow: 1;
    color: #bfa706; }
  .loader2 p {
    margin-top: 10px;
    margin-left: 50%; }
  .loader2 h1 {
    padding-left: 3px;
    padding-right: 3px; }
  .loader2 h3 {
    padding-left: 5px;
    padding-right: 5px; }

.pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center; }
  .pagination .current-page {
    color: #F91F5C; }
  .pagination .total-records {
    color: #4d4b5d;
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 11px;
    letter-spacing: 0.25px;
    opacity: 0.8;
    margin-bottom: 0; }
  .pagination .arrow-back,
  .pagination .arrow-front {
    cursor: pointer; }

.waitlist .rounds {
  width: 100%;
  text-align: center;
  margin-bottom: 50px; }
  .waitlist .rounds p {
    font-family: 'Abel';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    opacity: 0.8; }

.round-no {
  margin-bottom: 0;
  font-family: Geomanist;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  opacity: 0.8;
  margin-top: 25%;
  text-align: center; }

.round-no-waitlist {
  margin-bottom: 0;
  font-family: Geomanist;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  opacity: 0.8;
  margin-top: 5%;
  text-align: center; }

.round-header {
  margin-bottom: 0;
  font-family: Geomanist;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  opacity: 0.8; }

tr .player {
  text-align: center; }

.empty-records {
  text-align: center;
  width: 100%;
  padding: 20px; }
  .empty-records p {
    margin-bottom: 0;
    font-family: Geomanist;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 11px;
    letter-spacing: 0.25px;
    opacity: 0.8;
    margin-top: 5%;
    text-align: center;
    color: red; }

#th-empty {
  text-align: center; }

.play .play-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

.play .header-box {
  width: 100%;
  text-align: center; }

.play-cards {
  background: #F91F5C;
  border-radius: 10px;
  height: 126px;
  padding: 5px;
  flex-grow: 1;
  margin: 0 8px;
  cursor: pointer; }
  .play-cards .icon-box {
    height: 89px;
    background: #c71a4b;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px; }
  .play-cards .text-box {
    background: #951437;
    border-radius: 8px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .play-cards .text-box p {
      margin-bottom: 0;
      font-family: Geomanist;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 11px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #FFFFFF;
      opacity: 0.8; }

.score-cards-with-title {
  background: #201b3f;
  border-radius: 5px;
  padding: 5px 0;
  margin: 0 5px 50px 5px;
  flex-grow: 1; }
  .score-cards-with-title .title {
    font-family: 'Gobold Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 8px;
    color: #DFE2E9;
    opacity: 0.5;
    justify-content: center;
    margin: 5px 0; }
  .score-cards-with-title .number-card {
    background: #26204c;
    border-radius: 5px;
    height: 38.41px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0 5px; }
    .score-cards-with-title .number-card .inner {
      height: 32.79px;
      background: #332d5f;
      border-radius: 5px;
      margin: 0 1px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px; }
      .score-cards-with-title .number-card .inner.logo {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: transparent; }
      .score-cards-with-title .number-card .inner h1 {
        font-family: 'Gobold Bold';
        margin: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 11px;
        letter-spacing: 0.25px;
        color: #FFFFFF; }

.score-cards {
  background: #26204c;
  border-radius: 5px;
  padding: 8px 0;
  margin: -5px 5px 50px 5px;
  flex-grow: 1; }
  .score-cards .number-card {
    background: #26204c;
    border-radius: 5px;
    height: 38.41px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0 5px; }
    .score-cards .number-card .inner {
      height: 47px;
      background: #332d5f;
      border-radius: 5px;
      margin: 0 2px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px; }
      .score-cards .number-card .inner.logo {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: transparent; }
      .score-cards .number-card .inner h1 {
        font-family: 'Gobold Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 11px;
        margin: 0;
        letter-spacing: 0.25px;
        color: #FFFFFF; }

.grand-box {
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .grand-box div {
    margin: -45px 20px;
    height: 50px;
    width: 70%;
    background-color: #26204c;
    justify-self: flex-end; }

.particles {
  position: fixed;
  width: 100%;
  max-width: 750px;
  height: 175px;
  padding: 5px 20px 5px 5px; }

.top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center; }

.button-wrapper {
  width: 100%;
  text-align: center;
  margin: 20px 0 80px 0; }

.rewards-unlocked {
  text-align: center; }

.balance-card {
  background: #25223D;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 83px;
  flex-grow: 1;
  margin: 0 8px;
  padding: 10px; }
  .balance-card div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .balance-card div img {
      margin: 0 10px 0 0; }
    .balance-card div p {
      margin-bottom: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 11px;
      letter-spacing: 0.25px;
      color: #FFFFFF;
      opacity: 0.8; }
  .balance-card .price {
    font-family: 'Gobold Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 11px;
    text-align: left;
    letter-spacing: 0.25px;
    color: #FFFFFF; }

