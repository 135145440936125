.results{

    .rounds{
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
        p{
            font-family: 'Abel';
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 11px;
            letter-spacing: 0.25px;
            color: #FFFFFF;
            opacity: 0.8;
        }
    }
}